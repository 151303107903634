import React, { useRef } from 'react';
import { Portal } from 'react-portal';
import { useClickAway, useKey } from 'react-use';
import useLockBodyScroll from '@components/hooks/useLockBodyScroll';
import { Button } from '@supertakst/ui-common';
import { motion } from 'framer-motion';

type Props = {
  header: string;
  text: string;
  icon?: React.ElementType;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmDialogContent = ({ header, text, onCancel, onConfirm, icon }: Props) => {
  const contentRef = useRef(null);
  useKey('Escape', () => onCancel());
  useClickAway(contentRef, () => {
    onCancel();
  });

  const IconComponent = icon;
  return (
    <motion.div
      ref={contentRef}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="mt-[10vh] max-w-[90%] lg:max-w-[600px] w-full bg-white rounded drop-shadow-lg overflow-hidden"
    >
      <h2 className="text-xl font-medium text-supertakst-blue-900 flex items-center p-5 border-b border-b-[#020C56]/6 border-solid">
        {IconComponent && <IconComponent className="mr-3" />}
        {header}
      </h2>
      <div className="p-5 text-base text-supertakst-blue-900">{text}</div>
      <div className="mt-5 flex p-5 space-x-2 justify-end">
        <Button type="button" buttonType="secondary" onClick={() => onCancel()}>
          Avbryt
        </Button>
        <Button type="button" onClick={() => onConfirm()}>
          Slett
        </Button>
      </div>
    </motion.div>
  );
};

const ConfirmDialog = (props: Props) => {
  useLockBodyScroll();
  return (
    <Portal>
      <div className="z-40 fixed inset-0 bg-supertakst-blue-900 bg-opacity-75" />
      <div className="z-40 fixed w-screen h-screen inset-0 flex items-start justify-center">
        <ConfirmDialogContent {...props} />
      </div>
    </Portal>
  );
};

export default ConfirmDialog;
