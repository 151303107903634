import { FormProvider } from 'react-hook-form';
import Areal from '@components/common/Areal/v1';
import { getCheckHeader } from '@utils/modelUtils';
import usePartForm from '@components/hooks/usePartForm';
import { StandalonePartTypes, v1 } from '@supertakst/model-common';
import { AccordionContextProvider } from '@components/common/Accordion';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import Joi from 'joi';

type Props = {
  defaultValues: v1.Areal;
  doOnSave: (values) => void;
};
const ArealForm = ({ defaultValues, doOnSave }: Props) => {
  const [formMethods] = usePartForm<v1.Areal>(
    ArealSchema,
    defaultValues,
    StandalonePartTypes.Areal.id,
    doOnSave
  );

  return (
    <FormProvider {...formMethods}>
      <form className="space-y-2.5" onChange={async () => {}}>
        <AccordionContextProvider keepOpen={true}>
          <SingleTextarea
            id="arealKommentar"
            header={getCheckHeader(1, StandalonePartTypes.Areal.id, 'arealKommentar')}
          />
        </AccordionContextProvider>
        <Areal id="arealer" header={getCheckHeader(1, StandalonePartTypes.Areal.id, 'arealer')} />
      </form>
    </FormProvider>
  );
};

export const ArealItemSchema = Joi.object({
  name: Joi.string().required(),
  comment: Joi.string().allow(null, '').presence('optional'), // TODO remove this when testing is done
  prom: Joi.number().required(),
  srom: Joi.number().required(),
  bra: Joi.number().allow('', null),
  promBeskrivelse: Joi.string().allow('', null),
  sromBeskrivelse: Joi.string().allow('', null),
  ovrigBygg: Joi.boolean().allow('', null),
}).unknown(true);

export const ArealSchema = Joi.object({
  partType: Joi.string(),
  arealKommentar: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }).presence('optional'),
  arealer: Joi.array().items(ArealItemSchema),
  touched: Joi.boolean().presence('optional'),
})
  .label('Areal')
  .messages({ '*': 'Dette feltet er påkrevet' });

export default ArealForm;
