import React from 'react';
import Input from '@components/common/atoms/Input';
import Joi from 'joi';

type Props = {
  id: string;
};

export const MatrikkelForm = ({ id }: Props) => {
  return (
    <div className="grid grid-cols-3 gap-x-5 gap-y-10">
      <Input className="col-span-3" header="Adresse" id={`${id}.adresse`} type="text" />
      <Input
        header="Postnummer"
        id={`${id}.postnr`}
        type="text"
        pattern="[0-9]{4}"
        inputMode="numeric"
      />
      <Input className="col-span-2" header="Sted" id={`${id}.poststed`} type="text" />
      <Input header="Knr" id={`${id}.knr`} type="number" />
      <Input header="Gnr" id={`${id}.gnr`} type="number" />
      <Input header="Bnr" id={`${id}.bnr`} type="number" />
      <Input header="Fnr" id={`${id}.fnr`} type="number" />
      <Input header="Snr" id={`${id}.snr`} type="number" />
      <Input header="Anr" id={`${id}.anr`} type="number" />
      <Input header="Leilighetsnummer" id={`${id}.lnr`} type="text" />
    </div>
  );
};

export const MatrikkelSchema = Joi.object({
  touched: Joi.boolean().presence('optional'),
  adresse: Joi.string(),
  postnr: Joi.alternatives(
    Joi.string()
      .pattern(/^[0-9]{4}$/)
      .required()
      .messages({ 'string.pattern.base': 'Ugyldig postnummer' }),
    Joi.number().integer().min(1).required().messages({ 'number.min': 'Ugyldig postnummer' })
  ),
  poststed: Joi.string(),

  knr: Joi.number().integer().min(0).required().messages({ 'number.min': 'Ugyldig kommunenummer' }),
  gnr: Joi.number().integer().min(0).required().messages({ 'number.min': 'Ugyldig gårdsnummer' }),
  bnr: Joi.number().integer().min(0).required().messages({ 'number.min': 'Ugyldig bruksnummer' }),
  fnr: Joi.number()
    .integer()
    .min(0)
    .allow('', null)
    .messages({ 'number.min': 'Ugyldig festenummer' }),
  snr: Joi.number()
    .integer()
    .min(0)
    .allow('', null)
    .messages({ 'number.min': 'Ugyldig seksjonsnummer' }),
  anr: Joi.number()
    .integer()
    .min(0)
    .allow('', null)
    .messages({ 'number.min': 'Ugyldig andelsnummer' }),
  lnr: Joi.string().allow('', null),
}).preferences({
  messages: {
    '*': 'Dette feltet er påkrevet',
  },
});
