import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import Joi from 'joi';

import CheckBoolean from '@components/common/CheckBoolean/v1';
import SingleInput from '@components/common/SingleInput';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import CheckText from '@components/common/CheckText/v1';
import { getCheckHeader } from '@utils/modelUtils';
import usePartForm from '@components/hooks/usePartForm';
import { StandalonePartTypes, v1 } from '@supertakst/model-common';

type Props = {
  defaultValues: v1.TekniskVerdi;
  doOnSave: (values) => void;
};
const TekniskVerdiForm = ({ defaultValues, doOnSave }: Props) => {
  const [formMethods] = usePartForm<v1.TekniskVerdi>(
    TekniskVerdiSchema,
    defaultValues,
    StandalonePartTypes.TekniskVerdi.id,
    doOnSave
  );

  const { watch, setValue } = formMethods;

  const markedsverdiSettes = watch('markedsverdiSettes.value');
  const fellesgjeldSettes = watch('fellesgjeldSettes.value');
  const tekniskVerdiSettes = watch('tekniskVerdiSettes.value');

  useEffect(() => {
    if (markedsverdiSettes !== true) {
      setValue('markedsverdi.value', null);
      setValue('markedsverdiFritekst.value', null);
      setValue('tomtType.value', null);
      setValue('tomtAreal.value', null);
      setValue('tekniskVerdiSettes.value', null);
    }
  }, [markedsverdiSettes, setValue]);

  useEffect(() => {
    if (fellesgjeldSettes !== true) {
      setValue('fellesgjeld.value', null);
    }
  }, [fellesgjeldSettes, setValue]);

  useEffect(() => {
    if (tekniskVerdiSettes !== true) {
      setValue('byggeKostnad.value', null);
      setValue('verdiReduksjon.value', null);
      setValue('tomteVerdi.value', null);
      setValue('byggeKostnad.value', null);
      setValue('byggeKostnad.comment', null);
      setValue('verdiReduksjon.value', null);
      setValue('verdiReduksjon.comment', null);
      setValue('tomteVerdi.value', null);
      setValue('tomteVerdi.comment', null);
    }
  }, [tekniskVerdiSettes, setValue]);

  return (
    <FormProvider {...formMethods}>
      <form className="space-y-2.5" onChange={async () => {}}>
        <CheckBoolean
          id="markedsverdiSettes"
          header={getCheckHeader(1, StandalonePartTypes.TekniskVerdi.id, 'markedsverdiSettes')}
          hideComment={true}
        />
        {markedsverdiSettes && (
          <>
            <SingleInput
              id="markedsverdi"
              header={getCheckHeader(1, StandalonePartTypes.TekniskVerdi.id, 'markedsverdi')}
              type="number"
              format="number"
            />
            <SingleTextarea
              id="markedsverdiFritekst"
              header={getCheckHeader(
                1,
                StandalonePartTypes.TekniskVerdi.id,
                'markedsverdiFritekst'
              )}
            />
            <CheckText
              id="tomtType"
              header={getCheckHeader(1, StandalonePartTypes.TekniskVerdi.id, 'tomtType')}
              alternatives={[
                { value: 'selveier', label: 'Selveier' },
                { value: 'festetomt', label: 'Festetomt' },
              ]}
              hideComment={true}
            />
            <SingleInput
              id="tomtAreal"
              header={getCheckHeader(1, StandalonePartTypes.TekniskVerdi.id, 'tomtAreal')}
              type="number"
              format="number"
            />
          </>
        )}
        <CheckBoolean
          id="fellesgjeldSettes"
          header={getCheckHeader(1, StandalonePartTypes.TekniskVerdi.id, 'fellesgjeldSettes')}
          hideComment={true}
        />
        {fellesgjeldSettes && (
          <SingleInput
            id="fellesgjeld"
            header={getCheckHeader(1, StandalonePartTypes.TekniskVerdi.id, 'fellesgjeld')}
            type="number"
            format="number"
          />
        )}
        {markedsverdiSettes && (
          <CheckBoolean
            id="tekniskVerdiSettes"
            header={getCheckHeader(1, StandalonePartTypes.TekniskVerdi.id, 'tekniskVerdiSettes')}
            hideComment={true}
          />
        )}

        {tekniskVerdiSettes && (
          <>
            <SingleInput
              id="byggeKostnad"
              header={getCheckHeader(1, StandalonePartTypes.TekniskVerdi.id, 'byggeKostnad')}
              type="number"
              hideComment={false}
              format="number"
            />
            <SingleInput
              id="verdiReduksjon"
              header={getCheckHeader(1, StandalonePartTypes.TekniskVerdi.id, 'verdiReduksjon')}
              type="number"
              format="number"
              hideComment={false}
            />
            <SingleInput
              id="tomteVerdi"
              header={getCheckHeader(1, StandalonePartTypes.TekniskVerdi.id, 'tomteVerdi')}
              type="number"
              format="number"
              hideComment={false}
            />
          </>
        )}
      </form>
    </FormProvider>
  );
};

export const TekniskVerdiSchema = Joi.object({
  partType: Joi.string(),
  markedsverdiSettes: {
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  },
  fellesgjeldSettes: {
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  },
  tekniskVerdiSettes: {
    value: Joi.when('/markedsverdiSettes.value', { is: true, then: Joi.boolean().required() }),
    touched: Joi.boolean().presence('optional'),
  },

  markedsverdi: Joi.object({
    value: Joi.when(Joi.ref('/markedsverdiSettes.value'), {
      is: true,
      then: Joi.number().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  markedsverdiFritekst: {
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  },
  tomtType: Joi.object({
    value: Joi.when(Joi.ref('/markedsverdiSettes.value'), {
      is: true,
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  tomtAreal: Joi.object({
    value: Joi.when(Joi.ref('/markedsverdiSettes.value'), {
      is: true,
      then: Joi.number().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),

  fellesgjeld: Joi.object({
    value: Joi.when(Joi.ref('/fellesgjeldSettes.value'), {
      is: true,
      then: Joi.number().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),

  byggeKostnad: Joi.object({
    value: Joi.when(Joi.ref('/tekniskVerdiSettes.value'), {
      is: true,
      then: Joi.number().required(),
    }),
    comment: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  verdiReduksjon: Joi.object({
    value: Joi.when(Joi.ref('/tekniskVerdiSettes.value'), {
      is: true,
      then: Joi.number().required(),
    }),
    comment: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  tomteVerdi: Joi.object({
    value: Joi.when(Joi.ref('/tekniskVerdiSettes.value'), {
      is: true,
      then: Joi.number().required(),
    }),
    comment: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
}).messages({ '*': 'Dette feltet er påkrevet' });
export default TekniskVerdiForm;
