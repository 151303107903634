import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CheckCardContent, CheckCardHeader, CheckCardItem } from 'components/common/CheckCard';
import CheckHeader from '@components/common/CheckHeader';
import Header from '@components/common/Header';
import Input from '@components/common/atoms/Input';
import { Button } from '@supertakst/ui-common';
import Textarea from '@components/common/atoms/Textarea';
import DeleteButton from '@components/common/atoms/DeleteButton';
import Tooltip from '@components/common/Tooltip';

type Props = {
  id: string;
  header: string;
};
const Areal = ({ id, header }: Props) => {
  const { control, setValue, getValues, trigger } = useFormContext();
  const helpButtons = ['U. etasje', '1. etasje', '2. etasje', '3. etasje', 'Kjeller', 'Loft'];

  const { fields, append, remove } = useFieldArray({
    control,
    name: id,
  });

  const setValueOptions = { shouldTouch: true, shouldValidate: true, shouldDirty: true };

  useEffect(() => {
    if (fields.length === 0) {
      append({
        name: '',
        prom: '',
        srom: '',
        promBeskrivelse: '',
        sromBeskrivelse: '',
      });
    }
  }, [fields, append]);

  const appendItem = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    append({ name: '', comment: '', prom: '', srom: '', promBeskrivelse: '', sromBeskrivelse: '' });
    trigger(id);
  };

  const handleBtnClick = (ev: React.MouseEvent<HTMLButtonElement>, index: number) => {
    ev.preventDefault();
    const btnElement = ev.target as HTMLElement;
    setValue(`${id}[${index}].name`, btnElement.innerText, setValueOptions);
    trigger(`${id}[${index}].name`);
  };

  const updateBraValue = (index: number) => {
    let [prom, srom] = getValues([`${id}[${index}].prom`, `${id}[${index}].srom`]);
    prom = parseInt(prom);
    srom = parseInt(srom);
    setValue(
      `${id}[${index}].bra`,
      (!isNaN(prom) ? prom : 0) + (!isNaN(srom) ? srom : 0),
      setValueOptions
    );
  };

  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>{header}</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>
      <CheckCardContent>
        <ul className="pb-5">
          {fields.map((field, index) => (
            <li key={field.id} className="py-4">
              <Input id={`${id}[${index}].name`} type="text" header="Navn" />
              <Header as="h4" className="text-center">
                Her skriver du inn navnet på etasjen du måler - eks. 1 Etasje, eller bruker knappene
                under
              </Header>
              <div className="flex flex-wrap justify-center">
                {helpButtons.map((btn) => (
                  <Button
                    buttonType="secondary"
                    key={btn}
                    className="m-2 w-32"
                    onClick={(ev) => handleBtnClick(ev, index)}
                  >
                    {btn}
                  </Button>
                ))}
              </div>
              <div className="my-4">
                <Input type="checkbox" id={`${id}[${index}].ovrigBygg`} header="Øvrig bygg" />
              </div>
              <div className="flex flex-row gap-4">
                <Input
                  id={`${id}[${index}].prom`}
                  type="number"
                  min={0}
                  header="Primærrom (p-rom m2)"
                  onBlur={() => updateBraValue(index)}
                />
                <Input
                  id={`${id}[${index}].srom`}
                  type="number"
                  min={0}
                  header="Sekundærrom (s-rom m2)"
                  onBlur={() => updateBraValue(index)}
                />
                <Input
                  id={`${id}[${index}].bra`}
                  type="number"
                  min={0}
                  header="Bruksareal (bra m2)"
                  readOnly
                />
              </div>
              <Textarea id={`${id}[${index}].promBeskrivelse`} header="Beskrivelse primærrom" />
              <Textarea id={`${id}[${index}].sromBeskrivelse`} header="Beskrivelse sekundærrom" />
              {index > 0 && (
                <DeleteButton className="mt-4" onClick={() => remove(index)}>
                  Slett
                </DeleteButton>
              )}
            </li>
          ))}
        </ul>
        <div>
          <Button data-test-action="appenditem" onClick={appendItem}>
            Legg til
          </Button>
        </div>
      </CheckCardContent>
    </CheckCardItem>
  );
};

export default Areal;
