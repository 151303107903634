import v3StandaloneParts from './v3';
import {
  default as GenerellInformasjon,
  GenerellInformasjonSchema,
} from './GenerellInformasjon/v4';

const StandaloneParts = {
  ...v3StandaloneParts,
  ['GenerellInformasjon']: {
    form: GenerellInformasjon,
    schema: GenerellInformasjonSchema,
  },
};

export default StandaloneParts;
