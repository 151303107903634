import { FormProvider } from 'react-hook-form';
import Areal from '@components/common/Areal/v1';
import { getCheckHeader } from '@utils/modelUtils';
import usePartForm from '@components/hooks/usePartForm';
import { StandalonePartTypes, v1 } from '@supertakst/model-common';
import { AccordionContextProvider } from '@components/common/Accordion';
import SingleTextarea from '@components/common/SingleTextarea/v2';
import Joi from 'joi';
import { useRouter } from 'next/router';

type Props = {
  defaultValues: v1.Areal;
  doOnSave: (values) => void;
};
const ArealForm = ({ defaultValues, doOnSave }: Props) => {
  const [formMethods] = usePartForm<v1.Areal>(
    ArealSchema,
    defaultValues,
    StandalonePartTypes.Areal.id,
    doOnSave
  );

  const {
    query: { version = '5' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  return (
    <FormProvider {...formMethods}>
      <form className="space-y-2.5" onChange={async () => {}}>
        <AccordionContextProvider keepOpen={true}>
          <SingleTextarea
            id="arealKommentar"
            header={getCheckHeader(partVersion, StandalonePartTypes.Areal.id, 'arealKommentar')}
            phrasesTarget={{
              header: getCheckHeader(partVersion, StandalonePartTypes.Areal.id, 'arealKommentar'),
              id: 'arealKommentar.value',
            }}
          />
        </AccordionContextProvider>
        <Areal
          id="arealer"
          header={getCheckHeader(partVersion, StandalonePartTypes.Areal.id, 'arealer')}
        />
      </form>
    </FormProvider>
  );
};

export const ArealItemSchema = Joi.object({
  name: Joi.string().required(),
  comment: Joi.string().allow(null, '').presence('optional'), // TODO remove this when testing is done
  prom: Joi.number().required(),
  srom: Joi.number().required(),
  bra: Joi.number().allow('', null),
  promBeskrivelse: Joi.string().allow('', null),
  sromBeskrivelse: Joi.string().allow('', null),
  ovrigBygg: Joi.boolean().allow('', null),
}).unknown(true);

export const ArealSchema = Joi.object({
  partType: Joi.string(),
  arealKommentar: Joi.object({
    value: Joi.string().allow('', null),
  }).presence('optional'),
  arealer: Joi.array().items(ArealItemSchema),
})
  .label('Areal')
  .messages({ '*': 'Dette feltet er påkrevet' });

export default ArealForm;
