import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CheckCardContent, CheckCardHeader, CheckCardItem } from 'components/common/CheckCard';
import CheckHeader from '@components/common/CheckHeader';
import Header from '@components/common/Header';
import Input from '@components/common/atoms/Input';
import { Button } from '@supertakst/ui-common';
import DeleteButton from '@components/common/atoms/DeleteButton';
import Tooltip from '@components/common/Tooltip';

type Props = {
  id: string;
  header: string;
};
const Areal = ({ id, header }: Props) => {
  const helpButtons = ['U. etasje', '1. etasje', '2. etasje', '3. etasje', 'Kjeller', 'Loft'];
  const setValueOptions = { shouldTouch: true, shouldValidate: true, shouldDirty: true };

  const { control, getValues, trigger, setValue } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: id,
  });

  const appendItem = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    append({
      name: '',
      interntBruksareal: '',
      eksterntBruksareal: '',
      innglassetBalkong: '',
      aapentAreal: '',
      lavHimlingshoyde: 0,
      ovrigBygg: false,
    });
    trigger(id);
  };

  const handleBtnClick = (ev: React.MouseEvent<HTMLButtonElement>, index: number) => {
    ev.preventDefault();
    const btnElement = ev.target as HTMLElement;
    setValue(`${id}[${index}].name`, btnElement.innerText, setValueOptions);
    trigger(`${id}[${index}].name`);
  };

  const updateBraValue = (index: number) => {
    let [brai, brae, brab] = getValues([
      `${id}[${index}].interntBruksareal`,
      `${id}[${index}].eksterntBruksareal`,
      `${id}[${index}].innglassetBalkong`,
    ]);
    brai = parseInt(brai);
    brae = parseInt(brae);
    brab = parseInt(brab);
    setValue(
      `${id}[${index}].bra`,
      (!isNaN(brai) ? brai : 0) + (!isNaN(brae) ? brae : 0) + (!isNaN(brab) ? brab : 0),
      setValueOptions
    );
  };

  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>{header}</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>
      <CheckCardContent>
        <ul className="pb-5 space-y-2.5">
          {fields.map((field, index) => (
            <li key={field.id} className="py-4 border-b border-dashed">
              <div className="space-y-8">
                <div className="space-y-2">
                  <Input id={`${id}[${index}].name`} type="text" header="Navn" />
                  <Header as="h4" className="text-center">
                    Her skriver du inn navnet på etasjen du måler - eks. 1 Etasje, eller bruker
                    knappene under
                  </Header>
                  <div className="flex flex-wrap justify-start gap-4">
                    {helpButtons.map((btn) => (
                      <Button
                        buttonType="secondary"
                        key={btn}
                        className=" w-32"
                        onClick={(ev) => handleBtnClick(ev, index)}
                      >
                        {btn}
                      </Button>
                    ))}
                  </div>
                  <Input type="checkbox" id={`${id}[${index}].ovrigBygg`} header="Øvrig bygg" />
                </div>
                <div>
                  <div className="flex flex-row gap-4">
                    <Input
                      className="flex items-end"
                      id={`${id}[${index}].interntBruksareal`}
                      type="number"
                      header="BRA-i (internt bruksareal)"
                      onBlur={() => updateBraValue(index)}
                    />
                    <Input
                      className="flex items-end"
                      id={`${id}[${index}].eksterntBruksareal`}
                      type="number"
                      header="BRA-e (eksternt bruksareal)"
                      onBlur={() => updateBraValue(index)}
                    />
                    <Input
                      className="flex items-end"
                      id={`${id}[${index}].innglassetBalkong`}
                      type="number"
                      header="BRA-b (Innglasset balkong)"
                      onBlur={() => updateBraValue(index)}
                    />
                    <Input
                      className="flex items-end"
                      id={`${id}[${index}].bra`}
                      type="number"
                      min={0}
                      header="BRA m2 (bruksareal)"
                      readOnly
                    />
                  </div>
                </div>
                <div className="flex">
                  <Input
                    className="w-1/4 pr-4"
                    id={`${id}[${index}].aapentAreal`}
                    type="number"
                    header="TBA (terrasse- og balkongareal)"
                  />
                  <Input
                    className="w-1/4 pr-4"
                    id={`${id}[${index}].lavHimlingshoyde`}
                    type="number"
                    header="ALH (arealer med lav himlingshøyde)"
                  />
                </div>

                <DeleteButton className="mt-4" onClick={() => remove(index)}>
                  Slett
                </DeleteButton>
              </div>
            </li>
          ))}
        </ul>
        <div>
          <Button data-test-action="appenditem" onClick={appendItem}>
            Legg til
          </Button>
        </div>
      </CheckCardContent>
    </CheckCardItem>
  );
};

export default Areal;
