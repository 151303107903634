import { FormProvider } from 'react-hook-form';
import Areal from '@components/common/Areal/v3';
import { getCheckHeader } from '@utils/modelUtils';
import usePartForm from '@components/hooks/usePartForm';
import { StandalonePartTypes, v7 } from '@supertakst/model-common';
import { AccordionContextProvider } from '@components/common/Accordion';
import SingleTextarea from '@components/common/SingleTextarea/v2';
import Joi from 'joi';
import { useRouter } from 'next/router';

type Props = {
  defaultValues: v7.Areal;
  doOnSave: (values) => void;
};
const ArealForm = ({ defaultValues, doOnSave }: Props) => {
  const [formMethods] = usePartForm<v7.Areal>(
    ArealSchema,
    defaultValues,
    StandalonePartTypes.Areal.id,
    doOnSave
  );

  const {
    query: { version = '7' },
  } = useRouter();

  const partVersion = parseInt(version as string, 10);

  return (
    <FormProvider {...formMethods}>
      <form className="space-y-2.5" onChange={async () => {}}>
        <AccordionContextProvider keepOpen={true}>
          <SingleTextarea
            id="arealKommentar"
            header={getCheckHeader(partVersion, StandalonePartTypes.Areal.id, 'arealKommentar')}
            phrasesTarget={{
              header: getCheckHeader(partVersion, StandalonePartTypes.Areal.id, 'arealKommentar'),
              id: 'arealKommentar.value',
            }}
          />
        </AccordionContextProvider>
        <Areal id="bygninger" header="Arealer" />
      </form>
    </FormProvider>
  );
};

const validateInterdependentFields = (siblingName, helpers, thisValue) => {
  const [state] = helpers.state.ancestors.slice(-1);
  const thisPath = helpers.state.path as unknown as Array<string>;
  const siblingPath: Array<string> = thisPath?.slice(0, -1).concat(siblingName);
  const siblingValue = siblingPath?.reduce(
    (prevObj: object, key: string) => prevObj && prevObj[key],
    state
  );

  // Check if values are undefined or null, instead of just falsy
  const isThisValueAbsent = thisValue === undefined || thisValue === null;
  const isSiblingValueAbsent = siblingValue === undefined || siblingValue === null;

  if (isSiblingValueAbsent && isThisValueAbsent) return thisValue;
  if (!isSiblingValueAbsent && isThisValueAbsent) {
    return helpers.error('any.required');
  }
  return thisValue;
};

// In validation fix for value "0". Not working now.
const OldFieldsSchema = {
  promOld: Joi.custom((value, helpers) => {
    return validateInterdependentFields('sromOld', helpers, value);
  }),
  sromOld: Joi.custom((value, helpers) => {
    return validateInterdependentFields('promOld', helpers, value);
  }),
  braOld: Joi.number().allow('', null),
  promBeskrivelseOld: Joi.string().allow('', null),
  sromBeskrivelseOld: Joi.string().allow('', null),
};

export const ArealItemCommonSchema = {
  name: Joi.string().required(),
  // New standard
  interntBruksareal: Joi.number().min(0).required(),
  eksterntBruksareal: Joi.number().min(0).required(),
  innglassetBalkong: Joi.number().min(0).required(),
  lavHimlingshoyde: Joi.number().min(0).allow('', null).presence('optional'),
  bra: Joi.number().allow('', null),
  aapentAreal: Joi.number().min(0).required(),

  image: Joi.object({
    value: Joi.object({
      id: Joi.string(),
      url: Joi.string(),
    })
      .allow('', null)
      .presence('optional'),
  })
    .allow('', null)
    .presence('optional'),
};

// Note: For the first building in array (hovedbygg) promOld, and sromOld are required.
// For the rest of the buildings (i.e garage, shed, etc.) they are optional.
const FirstByggItemOldSchema = {
  ...OldFieldsSchema,
  promOld: Joi.number().min(0).required(),
  sromOld: Joi.number().min(0).required(),
};

const FirstByggArealItemSchema = { ...ArealItemCommonSchema, ...FirstByggItemOldSchema };
const ArealItemSchema = { ...ArealItemCommonSchema, ...OldFieldsSchema };

const FirstBygningSchema = Joi.object({
  name: Joi.string().required(),
  arealer: Joi.array().items(FirstByggArealItemSchema).min(1),
});
const BygningerSchema = Joi.object({
  name: Joi.string().required(),
  arealer: Joi.array().items(ArealItemSchema).min(1),
});

export const ArealSchema = Joi.object({
  partType: Joi.string(),
  arealKommentar: Joi.object({
    value: Joi.string().allow('', null),
  }).presence('optional'),
  bygninger: Joi.array().ordered(FirstBygningSchema).items(BygningerSchema).min(1),
})
  .label('ArealSchema')
  .messages({ '*': 'Dette feltet er påkrevet' });

export default ArealForm;
