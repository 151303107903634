import {
  default as GenerellInformasjon,
  GenerellInformasjonSchema,
} from './GenerellInformasjon/v5';
import { default as TekniskVerdi, TekniskVerdiSchema } from './TekniskVerdi/v5';
import { default as Areal, ArealSchema } from './Areal/v5';

const StandaloneParts = {
  ['GenerellInformasjon']: {
    form: GenerellInformasjon,
    schema: GenerellInformasjonSchema,
  },
  ['TekniskVerdi']: {
    form: TekniskVerdi,
    schema: TekniskVerdiSchema,
  },
  ['Areal']: {
    form: Areal,
    schema: ArealSchema,
  },
};

export default StandaloneParts;
