import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { CheckCardContent, CheckCardHeader, CheckCardItem } from 'components/common/CheckCard';
import CheckHeader from '@components/common/CheckHeader';
import Input from '@components/common/atoms/Input';
import { Button, DotsVerticalIcon, ExclamationIcon, PlusIcon, XIcon } from '@supertakst/ui-common';
import DeleteButton from '@components/common/atoms/DeleteButton';
import Tooltip from '@components/common/Tooltip';
import ConfirmDialog from '@components/common/ConfirmDialog';
import { CSS } from '@dnd-kit/utilities';

import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';

import { restrictToHorizontalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { SimpleImages } from '@components/common/Images';
import Header from '@components/common/Header';
import { Image } from '@supertakst/model-common';
import Textarea from '@components/common/atoms/Textarea';

type Props = {
  id: string;
  header: string;
};

const EMPTY_AREA = {
  name: '',
  interntBruksareal: '',
  eksterntBruksareal: '',
  innglassetBalkong: '',
  aapentAreal: '',
  lavHimlingshoyde: 0,
  image: null,
};

const setValueOptions = { shouldTouch: true, shouldValidate: true, shouldDirty: true };

const Areal = ({ id, header }: Props) => {
  const { query } = useRouter();
  const helpButtons = ['U. etasje', '1. etasje', '2. etasje', '3. etasje', 'Kjeller', 'Loft'];
  const { register, control, getValues, trigger, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: id,
  });
  const [confirmDeleteEtasje, setConfirmDeleteEtasje] = useState<number | undefined>(undefined);
  const imageFolder = `${process.env.NEXT_PUBLIC_CLOUDINARY_PRESET}/images/tilstandsrapporter/${query.id}`;

  const handleUploadImage = (index, data: Image[] | undefined) => {
    if (data && data.length) {
      setValue(`${id}[${index}].image.value`, data[0], setValueOptions);
    }
  };

  const handleDeleteImage = (index) => {
    setValue(`${id}[${index}].image.value`, undefined, setValueOptions);
  };

  useEffect(() => {
    if (fields.length === 0) {
      append(EMPTY_AREA);
    }
  }, [fields, append]);

  const appendItem = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    append(EMPTY_AREA);
    trigger(id);
  };

  const handleBtnClick = (ev: React.MouseEvent<HTMLButtonElement>, index: number) => {
    ev.preventDefault();
    const btnElement = ev.target as HTMLElement;
    setValue(`${id}[${index}].name`, btnElement.innerText, setValueOptions);
    trigger(`${id}[${index}].name`);
  };

  const updateBraValue = (index: number) => {
    let [brai, brae, brab] = getValues([
      `${id}[${index}].interntBruksareal`,
      `${id}[${index}].eksterntBruksareal`,
      `${id}[${index}].innglassetBalkong`,
    ]);
    brai = parseInt(brai);
    brae = parseInt(brae);
    brab = parseInt(brab);
    setValue(
      `${id}[${index}].bra`,
      (!isNaN(brai) ? brai : 0) + (!isNaN(brae) ? brae : 0) + (!isNaN(brab) ? brab : 0),
      setValueOptions
    );
  };

  const updateBraValueOld = (index: number) => {
    let [promOld, sromOld] = getValues([`${id}[${index}].promOld`, `${id}[${index}].sromOld`]);
    promOld = parseInt(promOld);
    sromOld = parseInt(sromOld);
    setValue(
      `${id}[${index}].braOld`,
      (!isNaN(promOld) ? promOld : 0) + (!isNaN(sromOld) ? sromOld : 0),
      setValueOptions
    );
  };

  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>{header}</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>
      <CheckCardContent>
        <ul className="pb-5 space-y-2.5">
          {fields.map((field, index) => {
            const image = getValues(`${id}[${index}].image.value`);
            return (
              <li key={field.id} className="py-4 border-b border-dashed">
                <div className="space-y-8">
                  <div className="space-y-2">
                    <Input id={`${id}[${index}].name`} type="text" header="Navn" />
                    <p className="text-sm text-[#303030]/80 max-w-prose">
                      Her skriver du inn navnet på etasjen du måler - eks. 1 Etasje, eller bruker
                      knappene under
                    </p>
                    <div className="flex flex-wrap justify-start gap-4">
                      {helpButtons.map((btn) => (
                        <Button
                          buttonType="secondary"
                          key={btn}
                          className=" w-32"
                          onClick={(ev) => handleBtnClick(ev, index)}
                        >
                          {btn}
                        </Button>
                      ))}
                    </div>
                  </div>
                  <div>
                    <Header as="h4" className="mb-4 border-b">
                      NS3940:2023
                    </Header>
                    <div className="flex flex-row gap-4">
                      <Input
                        className="flex items-end"
                        id={`${id}[${index}].interntBruksareal`}
                        type="number"
                        header="BRA-i (internt bruksareal)"
                        onBlur={() => updateBraValue(index)}
                      />
                      <Input
                        className="flex items-end"
                        id={`${id}[${index}].eksterntBruksareal`}
                        type="number"
                        header="BRA-e (eksternt bruksareal)"
                        onBlur={() => updateBraValue(index)}
                      />
                      <Input
                        className="flex items-end"
                        id={`${id}[${index}].innglassetBalkong`}
                        type="number"
                        header="BRA-b (Innglasset balkong)"
                        onBlur={() => updateBraValue(index)}
                      />
                      <Input
                        className="flex items-end"
                        id={`${id}[${index}].bra`}
                        type="number"
                        min={0}
                        header="BRA m2 (bruksareal)"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="flex items-end">
                    <Input
                      className="w-1/4 pr-4"
                      id={`${id}[${index}].aapentAreal`}
                      type="number"
                      header="TBA (terrasse- og balkongareal)"
                    />
                    <Input
                      className="w-1/4 pr-4"
                      id={`${id}[${index}].lavHimlingshoyde`}
                      type="number"
                      header="ALH (arealer med lav himlingshøyde)"
                    />
                  </div>

                  <div>
                    <Header as="h4" className="mb-4 border-b">
                      Fordeling mellom P-ROM og S-ROM
                    </Header>
                    <div className="flex flex-row gap-4">
                      <Input
                        id={`${id}[${index}].promOld`}
                        type="number"
                        header="Primærrom (p-rom m2)"
                        onBlur={() => updateBraValueOld(index)}
                      />
                      <Input
                        id={`${id}[${index}].sromOld`}
                        type="number"
                        header="Sekundærrom (s-rom m2)"
                        onBlur={() => updateBraValueOld(index)}
                      />
                      <Input
                        id={`${id}[${index}].braOld`}
                        type="number"
                        min={0}
                        header="Bruksareal (bra m2)"
                        readOnly
                      />
                    </div>
                    <Textarea
                      id={`${id}[${index}].promBeskrivelseOld`}
                      header="Beskrivelse primærrom"
                    />
                    <Textarea
                      id={`${id}[${index}].sromBeskrivelseOld`}
                      header="Beskrivelse sekundærrom"
                    />
                  </div>

                  <div>
                    <Header
                      as="h4"
                      className="text-color-supertakst-900 inline-block w-full mb-4 border-b"
                    >
                      Dokumentasjon / plantegning som viser skjulte kanaler/sjakter som er inkludert
                    </Header>

                    <div className="w-1/2">
                      <SimpleImages
                        folder={imageFolder}
                        onUploadDone={(image) => handleUploadImage(index, image)}
                        onDelete={() => handleDeleteImage(index)}
                        existingImages={image && [image]}
                      />
                      <input type="hidden" {...register(`${id}[${index}].image`)} />
                    </div>
                  </div>

                  {fields.length > 1 && (
                    <DeleteButton
                      type="button"
                      className="mt-4"
                      onClick={() => setConfirmDeleteEtasje(index)}
                    >
                      Slett etasje
                    </DeleteButton>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
        <div>
          <Button data-test-action="appenditem" onClick={appendItem}>
            Legg til etasje
          </Button>
        </div>
      </CheckCardContent>
      {confirmDeleteEtasje !== undefined && (
        <ConfirmDialog
          icon={ExclamationIcon}
          header="Slett etasje"
          text={`Er du sikker på at du vil slette denne etasjen? Dette kan ikke angres.`}
          onCancel={() => setConfirmDeleteEtasje(undefined)}
          onConfirm={() => {
            remove(confirmDeleteEtasje);
            setConfirmDeleteEtasje(undefined);
          }}
        />
      )}
    </CheckCardItem>
  );
};

const SortableItem = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const name = props.name || 'Ikke satt';
  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      onClick={props.onClick}
      className={classNames(
        props.activeTabIndex === props.index ? 'bg-[#FCFCFC]' : 'bg-white',
        props.activeTabIndex === props.index
          ? props.index === 0
            ? 'border-t border-r rounded-tr'
            : 'border-l border-t border-r rounded-t'
          : 'border-b',
        'group relative overflow-hidden py-4 text-center text-sm font-medium focus:z-10 px-12 min-w-[240px]',
        props.hasErrors ? 'text-red-600' : 'text-inherit'
      )}
    >
      {props.tabsCount > 1 && (
        <div
          className="flex hover:bg-supertakst-gray-900 absolute left-1 top-2 rounded"
          {...listeners}
        >
          <DotsVerticalIcon className="scale-75" />
          <DotsVerticalIcon className="scale-75 -ml-4" />
        </div>
      )}
      <div className="flex justify-center">
        <span className="overflow-hidden truncate max-w-[100px]">{name}</span>
        {props.tabsCount > 1 && (
          <button type="button" onClick={props.onDelete}>
            <XIcon className="ml-4 scale-75" />
          </button>
        )}
      </div>
    </div>
  );
};

const Bygninger = ({ id, header }: Props) => {
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 8 } }));
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: id,
  });
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [confirmDeleteBygning, setConfirmDeleteBygning] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (fields.length === 0) {
      append({
        name: 'Hovedbygg',
        arealer: [EMPTY_AREA],
      });
    }
  }, [fields, append]);

  const buildingWatch = useWatch({ control, name: id });

  useEffect(() => {
    (async () => {
      await trigger(id);
    })();
  }, [buildingWatch, trigger, id]);

  const appendItem = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    append({
      name: null,
      arealer: [EMPTY_AREA],
    });
    trigger(id);
  };

  const buildings = useWatch({
    control,
    name: id,
  });

  const items =
    buildings &&
    buildings
      .map((building, index) => {
        const field = fields[index];
        if (!field) return null;
        const buildingErrors = errors?.[id]?.[index] || {};
        const hasErrors = Object.keys(buildingErrors).length > 0;
        return { id: field.id, name: building.name, hasErrors };
      })
      .filter(Boolean);

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      const newOrder = arrayMove(items, oldIndex, newIndex);
      move(oldIndex, newIndex);
      setActiveTabIndex(newIndex);
      return newOrder;
    }
  }

  if (!items) return null;

  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>Bygninger</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>
      <CheckCardContent noPadding>
        <DndContext
          autoScroll={true}
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={items} strategy={horizontalListSortingStrategy}>
            <nav className="isolate flex mt-2 overflow-x-scroll bg-white" aria-label="Tabs">
              {items.map(({ id, name, hasErrors }, index) => (
                <SortableItem
                  key={id}
                  id={id}
                  name={name}
                  index={index}
                  hasErrors={hasErrors}
                  activeTabIndex={activeTabIndex}
                  tabsCount={items.length}
                  onClick={() => setActiveTabIndex(index)}
                  onDelete={() => setConfirmDeleteBygning(index)}
                />
              ))}
              <button
                type="button"
                onClick={(ev) => {
                  appendItem(ev);
                  setActiveTabIndex(items.length);
                }}
                className="px-4 border-b flex-1"
              >
                <PlusIcon />
              </button>
            </nav>
          </SortableContext>
        </DndContext>
        <div className="pt-4 pb-6 px-4 bg-[#FCFCFC]">
          <ul className="pb-5">
            {fields.map((field, index) => (
              <li
                key={field.id}
                className={classNames(
                  'py-4 border-b border-dashed',
                  activeTabIndex !== index && 'hidden'
                )}
              >
                <div className="space-y-8">
                  <div className="space-y-2">
                    <Input id={`${id}[${index}].name`} type="text" header="Navn" />
                    <p className="text-sm text-[#303030]/80">
                      Her skriver du inn navnet på bygget du måler
                    </p>
                    <Areal id={`${id}[${index}].arealer`} header={header} />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </CheckCardContent>
      {confirmDeleteBygning !== undefined && (
        <ConfirmDialog
          icon={ExclamationIcon}
          header="Slett tilleggsbygg"
          text={`Er du sikker på at du vil slette dette tilleggsbygget? Dette kan ikke angres.`}
          onCancel={() => setConfirmDeleteBygning(undefined)}
          onConfirm={() => {
            remove(confirmDeleteBygning);
            setActiveTabIndex(activeTabIndex > 0 ? activeTabIndex - 1 : 0);
            setConfirmDeleteBygning(undefined);
          }}
        />
      )}
    </CheckCardItem>
  );
};

export default Bygninger;
