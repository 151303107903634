import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import Input from '@components/common/atoms/Input';
import TextInput from '@components/common/CheckText/TextInput';

type Props = {
  id: string;
};

const HjemmelshaverForm = ({ id }: Props) => {
  const { control, setValue } = useFormContext();
  const alternatives = [
    { value: 'ja', label: 'Ja' },
    { value: 'nei', label: 'Nei' },
  ];

  const tilstedeValue = useWatch({
    control,
    name: `${id}.tilstedeVedInspeksjon`,
  });

  if (tilstedeValue === 'ja|Ja') {
    setValue(`${id}.representant`, null);
  }

  return (
    <div className="space-y-2.5">
      <Input id={`${id}.navn`} type="text" header="Navn" />
      <div>
        <p className="mb-2">Til stede ved inspeksjon</p>
        <Controller
          name={`${id}.tilstedeVedInspeksjon`}
          control={control}
          render={({ field: { onChange, onBlur, ref, name, value } }) => {
            return (
              <TextInput
                onChange={onChange}
                onBlur={onBlur}
                id={name}
                inputRef={ref}
                value={value}
                alternatives={alternatives}
              />
            );
          }}
        />
        {tilstedeValue === 'nei|Nei' && (
          <div className="py-4">
            <Input
              id={`${id}.representant`}
              type="text"
              header="Evt. navn på representant v/befaring"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HjemmelshaverForm;
