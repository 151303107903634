import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { CheckCardContent, CheckCardHeader, CheckCardItem } from 'components/common/CheckCard';
import CheckHeader from '@components/common/CheckHeader';
import Input from '@components/common/atoms/Input';
import { ArrowDownIcon, ArrowUpIcon, Button } from '@supertakst/ui-common';
import DeleteButton from '@components/common/atoms/DeleteButton';
import Tooltip from '@components/common/Tooltip';
import BoolInput from '@components/common/CheckBoolean/BoolInput';
import Header from '@components/common/Header';
import Textarea from '@components/common/atoms/Textarea';

type Props = {
  id: string;
  header: string;
};

const Tiltak = ({ id, header }: Props) => {
  const { control, trigger } = useFormContext();
  const arrayId = `${id}.values`;

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: arrayId,
  });

  const appendItem = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    append({ aar: '', beskrivelse: '', dokumentasjonFremlagt: null });
    trigger(arrayId);
  };

  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>{header}</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>
      <CheckCardContent>
        <ul>
          {fields.map((field, index) => {
            return (
              <li key={field.id} className="py-4 space-y-4 border-b-2 mb-4">
                <Input id={`${arrayId}[${index}].aar`} header="Årstall" type="text" />
                <Textarea id={`${arrayId}[${index}].beskrivelse`} header="Beskrivelse" />
                <div>
                  <Header as="label" className="inline-block pb-2">
                    Er det fremlagt dokumentasjon fra håndverker?
                  </Header>
                  <Controller
                    name={`${arrayId}[${index}].dokumentasjonFremlagt`}
                    control={control}
                    render={({ field: { onChange, onBlur, ref, name, value } }) => {
                      return (
                        <BoolInput
                          onChange={onChange}
                          onBlur={onBlur}
                          id={name}
                          inputRef={ref}
                          value={value}
                        />
                      );
                    }}
                  />
                </div>
                <div className="flex justify-between">
                  <DeleteButton onClick={() => remove(index)}>Slett</DeleteButton>
                  <div className="flex gap-4">
                    {index > 0 && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          move(index, index - 1);
                        }}
                        title="Trykk på pila for å flytte tiltaket opp"
                      >
                        <ArrowUpIcon />
                      </button>
                    )}
                    {index + 1 < fields.length && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          move(index, index + 1);
                        }}
                        title="Trykk på pila for å flytte tiltaket ned"
                      >
                        <ArrowDownIcon />
                      </button>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <div className="mt-4">
          <Button data-test-action="appenditem" buttonType="primary" onClick={appendItem}>
            Legg til {fields.length > 0 ? 'flere tiltak' : 'tiltak'}
          </Button>
        </div>
      </CheckCardContent>
    </CheckCardItem>
  );
};

export default Tiltak;
