import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import Joi from 'joi';

import CheckBoolean from '@components/common/CheckBoolean/v2';
import SingleInput from '@components/common/SingleInput';
import SingleTextarea from '@components/common/SingleTextarea/v2';
import CheckText from '@components/common/CheckText/v2';
import { getCheckHeader } from '@utils/modelUtils';
import usePartForm from '@components/hooks/usePartForm';
import { StandalonePartTypes, v1 } from '@supertakst/model-common';
import { useRouter } from 'next/router';

type Props = {
  defaultValues: v1.TekniskVerdi;
  doOnSave: (values) => void;
};
const TekniskVerdiForm = ({ defaultValues, doOnSave }: Props) => {
  const [formMethods] = usePartForm<v1.TekniskVerdi>(
    TekniskVerdiSchema,
    defaultValues,
    StandalonePartTypes.TekniskVerdi.id,
    doOnSave
  );

  const { watch, setValue } = formMethods;

  const markedsverdiSettes = watch('markedsverdiSettes.value');
  const fellesgjeldSettes = watch('fellesgjeldSettes.value');
  const tekniskVerdiSettes = watch('tekniskVerdiSettes.value');

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  useEffect(() => {
    if (markedsverdiSettes !== true) {
      setValue('markedsverdi.value', null);
      setValue('markedsverdiFritekst.value', null);
      setValue('tomtType.value', null);
      setValue('tomtType.comment', null);
      setValue('tomtAreal.value', null);
      setValue('tekniskVerdiSettes.value', null);
    }
  }, [markedsverdiSettes, setValue]);

  useEffect(() => {
    if (fellesgjeldSettes !== true) {
      setValue('fellesgjeld.value', null);
    }
  }, [fellesgjeldSettes, setValue]);

  useEffect(() => {
    if (tekniskVerdiSettes !== true) {
      setValue('byggeKostnad.value', null);
      setValue('verdiReduksjon.value', null);
      setValue('tomteVerdi.value', null);
      setValue('byggeKostnad.value', null);
      setValue('byggeKostnad.comment', null);
      setValue('verdiReduksjon.value', null);
      setValue('verdiReduksjon.comment', null);
      setValue('tomteVerdi.value', null);
      setValue('tomteVerdi.comment', null);
    }
  }, [tekniskVerdiSettes, setValue]);

  return (
    <FormProvider {...formMethods}>
      <form className="space-y-2.5" onChange={async () => {}}>
        <CheckBoolean
          id="markedsverdiSettes"
          header={getCheckHeader(
            partVersion,
            StandalonePartTypes.TekniskVerdi.id,
            'markedsverdiSettes'
          )}
          hideComment={true}
        />
        {markedsverdiSettes && (
          <>
            <SingleInput
              id="markedsverdi"
              header={getCheckHeader(
                partVersion,
                StandalonePartTypes.TekniskVerdi.id,
                'markedsverdi'
              )}
              type="number"
              format="number"
            />
            <SingleTextarea
              id="markedsverdiFritekst"
              header={getCheckHeader(
                partVersion,
                StandalonePartTypes.TekniskVerdi.id,
                'markedsverdiFritekst'
              )}
              phrasesTarget={{
                header: getCheckHeader(
                  partVersion,
                  StandalonePartTypes.TekniskVerdi.id,
                  'markedsverdiFritekst'
                ),
                id: 'markedsverdiFritekst.value',
              }}
            />
            <CheckText
              id="tomtType"
              header={getCheckHeader(partVersion, StandalonePartTypes.TekniskVerdi.id, 'tomtType')}
              alternatives={[
                { value: 'selveier', label: 'Selveier' },
                { value: 'festetomt', label: 'Festetomt' },
              ]}
              hideComment={false}
              phrasesTarget={{
                header: getCheckHeader(
                  partVersion,
                  StandalonePartTypes.TekniskVerdi.id,
                  'tomtType'
                ),
                id: 'tomtType.comment',
              }}
            />
            <SingleInput
              id="tomtAreal"
              header={getCheckHeader(partVersion, StandalonePartTypes.TekniskVerdi.id, 'tomtAreal')}
              type="number"
              format="number"
            />
          </>
        )}
        <CheckBoolean
          id="fellesgjeldSettes"
          header={getCheckHeader(
            partVersion,
            StandalonePartTypes.TekniskVerdi.id,
            'fellesgjeldSettes'
          )}
          hideComment={true}
        />
        {fellesgjeldSettes && (
          <SingleInput
            id="fellesgjeld"
            header={getCheckHeader(partVersion, StandalonePartTypes.TekniskVerdi.id, 'fellesgjeld')}
            type="number"
            format="number"
          />
        )}
        {markedsverdiSettes && (
          <CheckBoolean
            id="tekniskVerdiSettes"
            header={getCheckHeader(
              partVersion,
              StandalonePartTypes.TekniskVerdi.id,
              'tekniskVerdiSettes'
            )}
            hideComment={true}
          />
        )}

        {tekniskVerdiSettes && (
          <>
            <SingleInput
              id="byggeKostnad"
              header={getCheckHeader(
                partVersion,
                StandalonePartTypes.TekniskVerdi.id,
                'byggeKostnad'
              )}
              type="number"
              hideComment={false}
              format="number"
            />
            <SingleInput
              id="verdiReduksjon"
              header={getCheckHeader(
                partVersion,
                StandalonePartTypes.TekniskVerdi.id,
                'verdiReduksjon'
              )}
              type="number"
              format="number"
              hideComment={false}
            />
            <SingleInput
              id="tomteVerdi"
              header={getCheckHeader(
                partVersion,
                StandalonePartTypes.TekniskVerdi.id,
                'tomteVerdi'
              )}
              type="number"
              format="number"
              hideComment={false}
            />
          </>
        )}
      </form>
    </FormProvider>
  );
};

export const TekniskVerdiSchema = Joi.object({
  partType: Joi.string(),
  markedsverdiSettes: {
    value: Joi.boolean().required(),
  },
  fellesgjeldSettes: {
    value: Joi.boolean().required(),
  },
  tekniskVerdiSettes: {
    value: Joi.when('/markedsverdiSettes.value', { is: true, then: Joi.boolean().required() }),
  },

  markedsverdi: Joi.object({
    value: Joi.when(Joi.ref('/markedsverdiSettes.value'), {
      is: true,
      then: Joi.number().required(),
    }),
  }),
  markedsverdiFritekst: {
    value: Joi.string().allow('', null),
  },
  tomtType: Joi.object({
    value: Joi.when(Joi.ref('/markedsverdiSettes.value'), {
      is: true,
      then: Joi.string().required(),
    }),
    comment: Joi.string().allow('', null),
  }),
  tomtAreal: Joi.object({
    value: Joi.when(Joi.ref('/markedsverdiSettes.value'), {
      is: true,
      then: Joi.number().required(),
    }),
  }),

  fellesgjeld: Joi.object({
    value: Joi.when(Joi.ref('/fellesgjeldSettes.value'), {
      is: true,
      then: Joi.number().required(),
    }),
  }),

  byggeKostnad: Joi.object({
    value: Joi.when(Joi.ref('/tekniskVerdiSettes.value'), {
      is: true,
      then: Joi.number().required(),
    }),
    comment: Joi.string().allow('', null),
  }),
  verdiReduksjon: Joi.object({
    value: Joi.when(Joi.ref('/tekniskVerdiSettes.value'), {
      is: true,
      then: Joi.number().required(),
    }),
    comment: Joi.string().allow('', null),
  }),
  tomteVerdi: Joi.object({
    value: Joi.when(Joi.ref('/tekniskVerdiSettes.value'), {
      is: true,
      then: Joi.number().required(),
    }),
    comment: Joi.string().allow('', null),
  }),
}).messages({ '*': 'Dette feltet er påkrevet' });
export default TekniskVerdiForm;
