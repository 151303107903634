import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CheckCardContent, CheckCardHeader, CheckCardItem } from 'components/common/CheckCard';
import CheckHeader from '@components/common/CheckHeader';
import Input from '@components/common/atoms/Input';
import Header from '@components/common/Header';
import { Button } from '@supertakst/ui-common';
import DeleteButton from '@components/common/atoms/DeleteButton';
import Tooltip from '@components/common/Tooltip';
import Select from '@components/common/atoms/Select';

type Props = {
  id: string;
  header: string;
};

const BoligType = ({ id, header }: Props) => {
  const { control, trigger } = useFormContext();
  const arrayId = `${id}.rehabiliteringer`;

  const { fields, append, remove } = useFieldArray({
    control,
    name: arrayId,
  });

  const appendItem = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    append({ aar: '', beskrivelse: '', dokumentasjon: '' });
    trigger(arrayId);
  };

  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>{header}</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>
      <CheckCardContent>
        <div className="py-3 sm:grid sm:grid-cols-2 sm:gap-4">
          <Select
            className="sm:col-span-2"
            id={`${id}.boligTypeBeskrivelse`}
            header="Boligtype"
            alternatives={[
              { value: 'atriumshus', label: 'Atriumshus' },
              { value: 'enebolig', label: 'Enebolig' },
              { value: 'enebolig_rekke', label: 'Enebolig i rekke' },
              { value: 'enebolig_kjede', label: 'Enebolig i kjede' },
              { value: 'enebolig_med_utleie', label: 'Enebolig med utleie' },
              { value: 'rekkehus', label: 'Rekkehus' },
              { value: 'horisontaldelt_tomannsbolig', label: 'Horisontaldelt tomannsbolig' },
              { value: 'vertikaldelt_tomannsbolig', label: 'Vertikaldelt tomannsbolig' },
              { value: 'leilighet', label: 'Leilighet' },
              { value: 'leilighet_i_4mannsbolig', label: 'Leilighet i 4-mannsbolig' },
              { value: 'fritidsbolig', label: 'Fritidsbolig' },
              { value: 'leilighet_fritidsbolig', label: 'Leilighet / fritidsbolig' },
              { value: 'leilighet_i_boligblokk', label: 'Leilighet i boligblokk' },
              { value: 'leilighet_i_flermannsbolig', label: 'Leilighet i flermannsbolig' },
              { value: 'vaaningshus', label: 'Våningshus' },
              { value: 'anneks', label: 'Anneks' },
            ]}
            allowFreetext={true}
          />
          <Input id={`${id}.byggeAar`} header="Byggeår" type="number" />
          <Input id={`${id}.byggeAarBeskrivelse`} header="Kommentar byggeår" type="text" />
        </div>
        <ul className="py-5">
          <Header as="h4">Tiltak</Header>
          {fields.map((field, index) => (
            <li key={field.id} className="py-4">
              <Input id={`${arrayId}[${index}].aar`} header="Årstall" type="text" />
              <Input id={`${arrayId}[${index}].beskrivelse`} header="Beskrivelse" type="text" />
              <Input id={`${arrayId}[${index}].dokumentasjon`} header="Dokumentasjon" type="text" />
              <DeleteButton className="mt-4" onClick={() => remove(index)}>
                Slett
              </DeleteButton>
            </li>
          ))}
        </ul>
        <div>
          <Button data-test-action="appenditem" buttonType="primary" onClick={appendItem}>
            Legg til
          </Button>
        </div>
      </CheckCardContent>
    </CheckCardItem>
  );
};

export default BoligType;
