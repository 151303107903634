import Joi from 'joi';
import { FormProvider } from 'react-hook-form';

import CheckDate from '@components/common/CheckDate';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import SingleInput from '@components/common/SingleInput';
import SingleTextarea from '@components/common/SingleTextarea/v2';
import * as v6 from '@supertakst/model-common';
import { getCheckHeader } from '@utils/modelUtils';
import usePartForm from '@components/hooks/usePartForm';
import { Image, StandalonePartTypes } from '@supertakst/model-common';
import CheckImage from '@components/common/CheckImage/v2';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import BoligType from '../v4/BoligType';
import Matrikkel from '../v1/Matrikkel';
import Hjemmelshaver from '../v1/Hjemmelshaver';
import { MatrikkelSchema } from '../v1/Matrikkel/MatrikkelForm';
import Tiltak from '../v4/Tiltak';

type Props = {
  defaultValues: v6.GenerellInformasjon;
  doOnSave: (values) => void;
};
const GenerellInformasjonForm = ({ defaultValues, doOnSave }: Props) => {
  const [formMethods] = usePartForm<v6.GenerellInformasjon>(
    GenerellInformasjonSchema,
    defaultValues,
    StandalonePartTypes.GenerellInformasjon.id,
    doOnSave
  );

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch } = formMethods;
  const router = useRouter();
  const image = formMethods.getValues('image.value');
  const imageFolder = `${process.env.NEXT_PUBLIC_CLOUDINARY_PRESET}/images/tilstandsrapporter/${router.query.id}`;

  const byggeAr = watch('boligtypeInformasjon.byggeAar');
  useEffect(() => {
    trigger('boligtypeInformasjon.byggeAarBeskrivelse');
  }, [byggeAr, trigger]);

  const setValueOptions = { shouldTouch: true, shouldValidate: true, shouldDirty: true };

  const handleUpload = (data: Image[] | undefined) => {
    if (data && data.length) {
      formMethods.setValue('image.value', data[0], setValueOptions);
    }
  };

  const handleDelete = () => {
    formMethods.setValue('image.value', undefined, setValueOptions);
  };

  return (
    <FormProvider {...formMethods}>
      <form className="space-y-2.5" onChange={async () => {}}>
        <CheckDate
          id="befaringsDato"
          header={getCheckHeader(
            partVersion,
            StandalonePartTypes.GenerellInformasjon.id,
            'befaringsDato'
          )}
        />
        <Matrikkel
          id="matrikkel"
          header={getCheckHeader(
            partVersion,
            StandalonePartTypes.GenerellInformasjon.id,
            'matrikkel'
          )}
        />
        <CheckImage
          id="image"
          header="Bilde av boligen"
          folder={imageFolder}
          onUpload={(images) => handleUpload(images)}
          onDelete={() => handleDelete()}
          existingImages={image && [image]}
        />
        <BoligType
          id="boligtypeInformasjon"
          header={getCheckHeader(
            4,
            StandalonePartTypes.GenerellInformasjon.id,
            'boligtypeInformasjon'
          )}
        />
        <Tiltak
          id="tiltak"
          header={getCheckHeader(partVersion, StandalonePartTypes.GenerellInformasjon.id, 'tiltak')}
        />
        <Hjemmelshaver
          id="hjemmelshavere"
          header={getCheckHeader(
            partVersion,
            StandalonePartTypes.GenerellInformasjon.id,
            'hjemmelshavere'
          )}
        />
        <CheckBoolean
          hideComment={false}
          id="egenerklaringFremlagt"
          header={getCheckHeader(
            partVersion,
            StandalonePartTypes.GenerellInformasjon.id,
            'egenerklaringFremlagt'
          )}
          phrasesTarget={{
            header: getCheckHeader(
              partVersion,
              StandalonePartTypes.GenerellInformasjon.id,
              'egenerklaringFremlagt'
            ),
            id: 'egenerklaringFremlagt.comment',
          }}
        />
        <SingleInput
          id="rekvirent"
          header={getCheckHeader(
            partVersion,
            StandalonePartTypes.GenerellInformasjon.id,
            'rekvirent'
          )}
        />
        <SingleTextarea
          id="generellBeskrivelse"
          header={getCheckHeader(
            partVersion,
            StandalonePartTypes.GenerellInformasjon.id,
            'generellBeskrivelse'
          )}
          phrasesTarget={{
            header: getCheckHeader(
              partVersion,
              StandalonePartTypes.GenerellInformasjon.id,
              'generellBeskrivelse'
            ),
            id: 'generellBeskrivelse.value',
          }}
        />
        <SingleTextarea
          id="egnePremisser"
          header={getCheckHeader(
            partVersion,
            StandalonePartTypes.GenerellInformasjon.id,
            'egnePremisser'
          )}
          phrasesTarget={{
            header: getCheckHeader(
              partVersion,
              StandalonePartTypes.GenerellInformasjon.id,
              'egnePremisser'
            ),
            id: 'egnePremisser.value',
          }}
        />
      </form>
    </FormProvider>
  );
};

const HjemmelshaverSchema = Joi.object({
  values: Joi.array().items(
    Joi.object({
      navn: Joi.string(),
      tilstedeVedInspeksjon: Joi.string().valid('ja|Ja', 'nei|Nei').required(),
      representant: Joi.string().allow('', null),
    })
  ),
}).messages({ '*': 'Dette feltet er påkrevet' });
const RehabiliteringSchema = Joi.object({
  aar: Joi.string(),
  beskrivelse: Joi.string().allow('', null),
  dokumentasjonFremlagt: Joi.boolean().required(),
});

const boligtypeInformasjonSchema = Joi.object({
  boligTypeBeskrivelse: Joi.string(),
  byggeAar: Joi.number().integer().allow(null),
  byggeAarBeskrivelse: Joi.when('..byggeAar', { is: null, then: Joi.string().required() }),
}).messages({ '*': 'Dette feltet er påkrevet' });

export const GenerellInformasjonSchema = Joi.object({
  partType: Joi.string(),
  boligType: Joi.string().allow(null, ''),
  befaringsDato: Joi.object({
    value: Joi.date().iso().required(),
    touched: Joi.boolean().optional(),
  }),
  matrikkel: MatrikkelSchema,
  boligtypeInformasjon: boligtypeInformasjonSchema,
  tiltak: Joi.object({
    values: Joi.array().items(RehabiliteringSchema),
  }).allow(null),
  hjemmelshavere: HjemmelshaverSchema,
  egenerklaringFremlagt: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.string().allow(null, ''),
  }),
  rekvirent: Joi.object({
    value: Joi.string().allow(null, ''),
  }),
  generellBeskrivelse: Joi.object({
    value: Joi.string().allow(null, ''),
  }),
  egnePremisser: Joi.object({
    value: Joi.string().allow(null, ''),
  }),
  image: Joi.object({
    value: Joi.object({
      id: Joi.string(),
      url: Joi.string(),
    }).presence('optional'),
    visPaaForside: Joi.boolean().presence('optional'),
  }).presence('optional'),
}).messages({ '*': 'Dette feltet er påkrevet' });

export default GenerellInformasjonForm;
