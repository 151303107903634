import React from 'react';
import { CheckCardContent, CheckCardHeader, CheckCardItem } from 'components/common/CheckCard';
import CheckHeader from '@components/common/CheckHeader';
import Tooltip from '@components/common/Tooltip';
import { MatrikkelForm } from './MatrikkelForm';

type Props = {
  id: string;
  header: string;
};
const Matrikkel = ({ id, header }: Props) => {
  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>{header}</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>
      <CheckCardContent>
        <MatrikkelForm id={id} />
      </CheckCardContent>
    </CheckCardItem>
  );
};

export default Matrikkel;
