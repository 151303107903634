import { createContext, ReactNode, useContext, useState } from 'react';
type AccordionMode = 'collapsed' | 'open';

type AccordionContextProps = {
  mode: AccordionMode;
  setMode: (mode: AccordionMode) => void;
  setOpen: (string) => void;
  isOpen: (id: string) => boolean;
  keepOpen?: boolean;
};

const AccordionContext = createContext<AccordionContextProps | undefined>(undefined);

type AccordionProviderProps = {
  children: ReactNode;
  keepOpen?: boolean;
};
const AccordionContextProvider = ({ children, keepOpen = false }: AccordionProviderProps) => {
  const [mode, setMode] = useState<AccordionMode>('collapsed');
  const [openItem, setOpenItem] = useState<string | null>(null);

  const isOpen = (id: string) => {
    if (keepOpen) {
      return true;
    } else if (mode === 'open') {
      return true;
    } else {
      return id === openItem;
    }
  };

  const setOpen = (id: string) => {
    if (!keepOpen) {
      if (id === openItem) {
        setOpenItem(null);
      } else {
        setOpenItem(id);
      }
    }
  };

  const contextValues = {
    mode,
    setMode,
    setOpen,
    isOpen,
    keepOpen,
  };

  return <AccordionContext.Provider value={contextValues}>{children}</AccordionContext.Provider>;
};

function useAccordionContext() {
  const context = useContext(AccordionContext);
  if (context === undefined) {
    throw new Error('useAccordionContext must be used within a AccordionContextProvider');
  }
  return context;
}

export { AccordionContextProvider, useAccordionContext };
