import React from 'react';
import CheckHeader from '@components/common/CheckHeader';
import { CheckCardContent, CheckCardHeader, CheckCardItem } from 'components/common/CheckCard';
import { SimpleImages } from '@components/common/Images';
import Tooltip from '@components/common/Tooltip';
import { Image } from '@supertakst/model-common';
import Input from '@components/common/atoms/Input';
type Props = {
  id: string;
  header: string;
  folder: string;
  onUpload: (images) => void;
  onDelete: () => void;
  existingImages?: Image[];
};

const CheckImage = ({ id, header, folder, onUpload, onDelete, existingImages }: Props) => {
  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>{header}</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>

      <CheckCardContent>
        <div className="w-full md:w-2/3 lg:w-1/2 max-w-lg pb-4">
          <SimpleImages
            folder={folder}
            onUploadDone={onUpload}
            onDelete={onDelete}
            existingImages={existingImages}
          />
        </div>
        <Input
          type="checkbox"
          id={`${id}.visPaaForside`}
          header="Bruk bilde på forsiden av rapporten"
        />
      </CheckCardContent>
    </CheckCardItem>
  );
};

export default CheckImage;
