import v6standaloneParts from './v6';
import { default as Areal, ArealSchema } from './Areal/v7';

const StandaloneParts = {
  ...v6standaloneParts,
  ['Areal']: {
    form: Areal,
    schema: ArealSchema,
  },
};

export default StandaloneParts;
