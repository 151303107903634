import React from 'react';
import CheckHeader from '@components/common/CheckHeader';
import { CheckCardContent, CheckCardHeader, CheckCardItem } from 'components/common/CheckCard';
import Datepicker from '@components/common/atoms/Datepicker';
import Tooltip from '@components/common/Tooltip';

type Props = {
  id: string;
  header: string;
};

const CheckDate = ({ id, header }: Props) => {
  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>{header}</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>
      <CheckCardContent>
        <div className="w-full md:w-2/3 lg:w-1/2 max-w-lg">
          <Datepicker id={id} inline />
        </div>
      </CheckCardContent>
    </CheckCardItem>
  );
};

export default CheckDate;
