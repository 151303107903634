import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CheckCardContent, CheckCardHeader, CheckCardItem } from 'components/common/CheckCard';
import CheckHeader from '@components/common/CheckHeader';
import { Button } from '@supertakst/ui-common';
import DeleteButton from '@components/common/atoms/DeleteButton';
import Tooltip from '@components/common/Tooltip';
import HjemmelshaverForm from './HjemmelshaverForm';

type Props = {
  id: string;
  header: string;
};

const Hjemmelshaver = ({ id, header }: Props) => {
  const { control, trigger } = useFormContext();
  const arrayId = `${id}.values`;

  const { fields, append, remove } = useFieldArray({
    control,
    name: arrayId,
  });

  const appendItem = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    append({ navn: '', tilstedeVedInspeksjon: '', representant: null });
    trigger(arrayId);
  };

  useEffect(() => {
    if (fields.length === 0) {
      append({ navn: '', tilstedeVedInspeksjon: '', representant: null });
    }
  }, [fields, append]);

  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>{header}</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>
      <CheckCardContent>
        <ul>
          {fields.map((field, index) => (
            <li key={field.id} className="pb-10">
              <HjemmelshaverForm id={`${arrayId}[${index}]`} />
              {index > 0 && (
                <DeleteButton className="mt-4" onClick={() => remove(index)}>
                  Slett
                </DeleteButton>
              )}
            </li>
          ))}
        </ul>
        <Button data-test-action="appenditem" onClick={appendItem}>
          Legg til flere hjemmelshavere
        </Button>
      </CheckCardContent>
    </CheckCardItem>
  );
};

export default Hjemmelshaver;
