import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactDatepicker, { registerLocale } from 'react-datepicker';
import { formatDate } from '@utils/date';
import classnames from 'classnames';
import useFieldState from '@components/hooks/useFieldState';
import FieldError from '@components/common/FieldError';
import Arrow from '@components/common/atoms/Arrow';
import nb from 'date-fns/locale/nb';
import styles from './datepicker.module.scss';

type Props = {
  id: string;
  header?: string;
  inline?: boolean;
};

const Datepicker = ({ id, header, inline = false }: Props) => {
  registerLocale('nb', nb);
  const todaysDate = formatDate(new Date(), 'EEEE d. LLLL Y');
  const { control } = useFormContext();
  const [error, touched] = useFieldState(`${id}.value`);
  const dateName = `${id}.value`;

  return (
    <div className={classnames('w-full', styles.datepicker)} data-test-type="datepicker">
      <Controller
        name={dateName}
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <ReactDatepicker
            id={name}
            selected={value ? new Date(value) : null}
            onChange={onChange}
            todayButton={`I DAG - ${todaysDate}`}
            locale="nb"
            calendarStartDay={1}
            inline={inline}
            renderCustomHeader={({
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
              monthDate,
            }) => (
              <>
                {header && (
                  <div className="flex justify-center w-full text-xl font-bold my-4">{header}</div>
                )}
                <div className="flex flex-row items-center justify-between w-full p-6">
                  <div className="text-base font-medium">{formatDate(monthDate, 'MMMM yyyy')}</div>
                  <div className="flex">
                    {/* @TODO: Did not find this color in tailwindPreset. */}
                    <button
                      className="rounded-full w-10 h-10 bg-[#2B60E4]/10 flex justify-center items-center"
                      onClick={(ev) => {
                        ev.preventDefault();
                        decreaseMonth();
                      }}
                      disabled={prevMonthButtonDisabled}
                    >
                      <Arrow direction="left" />
                    </button>
                    <button
                      className="rounded-full w-10 h-10 bg-[#2B60E4]/10 ml-4 flex justify-center items-center"
                      onClick={(ev) => {
                        ev.preventDefault();
                        increaseMonth();
                      }}
                      disabled={nextMonthButtonDisabled}
                    >
                      <Arrow direction="right" />
                    </button>
                  </div>
                </div>
              </>
            )}
          />
        )}
      />
      {touched && error && <FieldError error={error} />}
    </div>
  );
};

export default Datepicker;
